<div *ngIf="documentSummarizationVisible"
     class="document-summary">
  <omg-collapse>
    <omg-collapse-toggle>
      <div om-layout
           om-layout-align="start center">
        <i class="fa-regular fa-wand-magic-sparkles"></i>
        <span class="document-summary-title">AI Document Summary</span>
        <omg-collapsed disableAnimation="true"><i class="fa fa-chevron-down"></i></omg-collapsed>
        <omg-expanded disableAnimation="true"><i class="fa fa-chevron-up"></i></omg-expanded>
      </div>
    </omg-collapse-toggle>
    <omg-expanded>
      <div class="document-summary-text">{{ summary.data.shortSummary }}</div>
      <div om-layout
           om-layout-align="start center"
           om-layout-gutter
           class="document-summary-timestamp">
        <i class="fa-regular fa-wand-magic-sparkles"></i><span>Generated {{ summary.createdAt | omgDate: '4y' }} at
          {{ summary.createdAt | omgDate: '12Time' }}</span>
      </div>
    </omg-expanded>
    <omg-collapsed>
      <div class="document-summary-text-collapsed">
        <span>{{ summary.data.shortSummary }}</span>
      </div>
    </omg-collapsed>
  </omg-collapse>
</div>
